import styles from "./FinishedScreens.module.css";
const FinishedScreens = () => {

     

  return (
    <div className={styles.finishedScreens}>
      <div className={styles.finishedScreensChild} />
      <div className={styles.finishedScreensItem} />
      <div className={styles.finishedScreensInner} />
      <div className={styles.rectangleDiv} />
      <img className={styles.mdiuserKeyIcon} alt="" src="/mdiuserkey.svg" />
      <img className={styles.mdiuserLockIcon} alt="" src="/mdiuserlock.svg" />
      <div className={styles.userNameemail}>User Name/Email</div>
      <div className={styles.password}>Password</div>
      
    

      <div className={styles.maskGroup}>
        <div className={styles.macbookPro141}>
          <img
            className={styles.cimenuAlt02Icon}
            
            alt=""
            src="/cimenualt02.svg"
          />
          <div className={styles.support}>Support</div>
          <div className={styles.myAccount}>My Account</div>
          <b className={styles.helloChad}>Hello, Chad!</b>
          <div className={styles.macbookPro141Child} />
          <div className={styles.macbookPro141Item} />
          <div className={styles.macbookPro141Inner} />
          <div className={styles.macbookPro141Child1} />
          <div className={styles.macbookPro141Child2} />
          <div className={styles.macbookPro141Child3} />
          <div className={styles.macbookPro141Child4} />
          <div className={styles.macbookPro141Child5} />
          <div className={styles.attendancedaily}>Attendance(Daily)</div>
          <div className={styles.absentdaily}>Absent(Daily)</div>
          <div className={styles.overtimemonthly}>Overtime(Monthly)</div>
          <div className={styles.leavemonthly}>Leave(Monthly)</div>
          <img
            className={styles.materialSymbolsarrowBackIoIcon}
            alt=""
            src="/materialsymbolsarrowbackiosnewrounded.svg"
          />
          <img
            className={styles.materialSymbolsarrowForwardIcon}
            alt=""
            src="/materialsymbolsarrowforwardiosrounded.svg"
          />
          <div className={styles.march}>March</div>
          <b className={styles.attendanceTracker}>Attendance Tracker</b>
        </div>
      </div>
      <img className={styles.macbookAir8} alt="" src="/macbook-air--8.svg" />
      <div className={styles.macbookAir11}>
        <div className={styles.macbookAir11Child} />
        <img
          className={styles.attendanceWorldLogo2}
          alt=""
          src="/attendance-world-logo-2@2x.png"
        />
        <div className={styles.macbookAir11Item} />
        {/* <b className={styles.login}>Login</b>  */}

        {/* <button className={styles.submit}>Submit</button> */}
        <div className={styles.enter}> <button>Enter</button> </div>

        <div className={styles.macbookAir11Inner}  />
        <div className={styles.macbookAir11Child1} />


        <div className={styles.forgotPassword}>Forgot Password</div>
        <img
          className={styles.materialSymbolstoggleOffOuIcon}
          alt=""
          src="/materialsymbolstoggleoffoutline.svg"
        />
        <div className={styles.rememberMe}>Remember me</div>

        {/* <div className={styles.usernameemployeeId}>Username/Employee ID</div> */}
        {/* <div className={styles.password1}>Password</div> */}



        <div className={styles.macbookAir11Child2} />
        <div className={styles.macbookAir11Child3} />
        <img className={styles.vectorIcon} alt="" src="/vector.svg" />
        <img className={styles.vectorIcon1} alt="" src="/vector1.svg" />
        <img className={styles.vectorIcon2} alt="" src="/vector2.svg" />
        <b className={styles.welcomeBack}>Welcome Back!</b>
        <div className={styles.pleaseLogInto}>
          Please log into Attendance world with your employee credentials
        </div>
      </div>
      <div className={styles.frameDiv} />
      <div className={styles.macbookAir19}>
        <div className={styles.macbookAir11Child} />
        <img
          className={styles.attendanceWorldLogo2}
          alt=""
          src="/attendance-world-logo-2@2x.png"
        />
        {/* <div className={styles.macbookAir11Item} /> */}
        {/* <b className={styles.login}>Login</b> */}

        {/* <button className={styles.submit}>Submit</button> */}
        <div className={styles.login}> <button>Login</button> </div>

{/* Input feilds is here :  */}

        {/* <div  className={styles.macbookAir11Inner }  />     */}
      <div className={styles.macbookAir11Inner }> <input type="text" name=""  placeholder="  Username/Employee ID" /> </div>
        {/* <div className={styles.macbookAir11Child1}  /> */}
        <div className={styles.macbookAir11Child1} > <input style={{backgroundColor:""}} type="text" name="" id="macbookAir11Child1 " placeholder="  Password" /> </div>
        
        <div className={styles.forgotPassword}> <a href="">Forgot Password</a> </div> 
        <img
          className={styles.materialSymbolstoggleOffOuIcon}
          alt=""
          src="/materialsymbolstoggleoffoutline.svg"
        />
        <div className={styles.rememberMe}>Remember me</div>

   {/* Main place holder is here :  */}

        {/* <div className={styles.usernameemployeeId}>Username/Employee ID</div>  */}
        {/* <div className={styles.password1}>Password</div> */}

          {/* <div className={styles.usernameemployeeId }  >Sadik </div> */}
          

        <div className={styles.macbookAir11Child2} />
        <div className={styles.macbookAir11Child3} />
        <img className={styles.vectorIcon} alt="" src="/vector3.svg" />
        <img className={styles.vectorIcon1} alt="" src="/vector4.svg" />
        <img className={styles.vectorIcon2} alt="" src="/vector5.svg" />
        <b className={styles.welcomeBack}>Welcome Back!</b>
        <div className={styles.pleaseLogInto}>
          Please log into Attendance world with your employee credentials
        </div>
      </div>
    </div>
  );
};

export default FinishedScreens;
 

// input#macbookAir11Inner\ {
//   width: 403px;
//   height: 73px;
//   margin-left: 71px;
//   font-size: 35px;
// }